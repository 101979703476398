<template>
 <CCol>
  <CToaster :autohide="2000">
        <template>           
        <div v-if="showSuccessToast">
          <CToast          
          :show.sync="showSuccessToast"
          color="success"
          >
            {{ successToastMessage }}
          </CToast>
        </div>   
      </template>         
      <template>           
        <div v-if="showErrorToast">
          <CToast          
          :show.sync="showErrorToast"
          color="danger"
          >
            {{ errorToastMessage }}
          </CToast>
        </div>   
      </template>
  </CToaster>        
  <CRow v-show="loading" style="max-width: 80rem;">
    <CCol col="12">
      <CCard v-show="loading">
        <CCardBody>
          <p class="text-center" v-show="loading">
            <CSpinner />
          </p >   
        </CCardBody>
      </CCard>
    </CCol>    
  </CRow>
  <CRow v-show="!loading" style="max-width: 80rem;"> 
    <CCol col="12">     
      <CCard>
        <CCardHeader>
          <b>Notifications</b>
        </CCardHeader>
        <CCardBody>        
          <CDataTable
            stripped
            :header="true"
            :items="notificationsTableData"
            :fields="notificationsTableFields"
            :pagination="false"
            :loading=loadingNotificationsTableData
          >
            <td slot="lens_name" slot-scope="{item}">
              {{ getLensName(item.lens_alias) }}
            </td>
            <td slot="status" slot-scope="{item}" >                  
              <CBadge :color="getBadge(item.status )">
                {{ item.status }}
              </CBadge>                                    
            </td>                   
            <td slot="update" slot-scope="{item}" >
              <CButton v-on:click="openWorkloadUpdate(item.workload_id)" :id="item.workload_id" block color="info" style="display: inline-grid;">
                <span class="material-icons md-18">update</span>
              </CButton>
            </td>             
          </CDataTable>
        </CCardBody>
        <CCardFooter>
          <CRow>
            <CCol col="12">
            <CButton color="light" class="float-left  col-sm-2 col-xl-1" @click="goBack">Back</CButton>
            </CCol>
          </CRow>
        </CCardFooter>
      </CCard>

        
    </CCol>    
  </CRow> 
  </CCol>    
</template>


<script>
import WorkloadService from '@/services/workload.services';
import LensService from '@/services/lense.services';


export default {
  components: {
  },  
  data() {
    return {

      workloadsNotifications: [],
      loadingNotificationsTableData: true,
      notificationsTableFields: [
        { key: 'workload_name', label: 'Workload', _style:'width:20%', sorter: false, filter: false  },
        { key: 'lens_name', label: 'Lens', _style:'width:20%', sorter: false, filter: false  },
        { key: 'status', label: 'Type', _style:'width:5%', sorter: false, filter: false  },
        { key: 'current_lens_version', label: 'Current Version', _style:'width:10%', sorter: false, filter: false  },
        { key: 'latest_lens_version', label: 'Latest Version', _style:'width:10%', sorter: false, filter: false  },
        { key: 'update', label: '', _style: 'width:1%', sorter: false, filter: false },

      ],
      notificationsTableData: [],
      loading: true,
      lenses: [],
      pillars: [],
      workload: {},      
      showSuccessToast: false,
      showErrorToast: false,
      successToastMessage: "",
      errorToastMessage: "",
    

    };
  },
  methods: {         
    goBack() {
      this.$router.push({path: '/workloads'})
    },  
    getLensName(lensAlias) {
      let lens = this.lenses.find(o => o.lens_alias === lensAlias);

      if (lens === undefined) {
        return lensAlias
      }

      return lens.lens_name;
    
    },  
    getPillarName(pillarId) {
      let pillar = this.pillars.find(o => o.pillar_id === pillarId);

      if (pillar === undefined) {
        return pillarId;
      }

      return pillar.pillar_name;
    
    },  
    openWorkloadUpdate(workloadId) {
      this.$router.push({path: `/workloads/${workloadId}/update`});
    },    
    getBadge (status) {
      switch (status) {
        case 'OUTDATED': return 'info'
        case 'DEPRECATED': return 'warning'
        default: 'secondary'
      }
    },      
    async getLensVersionDifference(lensAlias, baseLensVersion, targetLensVersion) { 
      try {
        let response = await LensService.getLensVersionDifference(lensAlias, baseLensVersion, targetLensVersion);
        return response;

      } catch (error) {
        console.log(error);
        this.errorToastMessage = "Error getting Lens Version Difference. Please try again later.";
        this.showErrorToast = true;
      };
    },
    
    async processWorkloadsNotifications() {
     
     for (var key in this.workloadsNotifications){
        let notification = {};
        notification['workload_name'] = this.workloadsNotifications[key].lens_upgrade_summary.workload_name;
        notification['workload_id'] = this.workloadsNotifications[key].lens_upgrade_summary.workload_id;
        notification['lens_alias'] = this.workloadsNotifications[key].lens_upgrade_summary.lens_alias;
        notification['current_lens_version'] = this.workloadsNotifications[key].lens_upgrade_summary.current_lens_version;
        notification['latest_lens_version'] = this.workloadsNotifications[key].lens_upgrade_summary.latest_lens_version;
        if(this.workloadsNotifications[key].type === "LENS_VERSION_UPGRADED" ){          
        notification['status'] = "OUTDATED";
        } else if (this.workloadsNotifications[key].type === "LENS_VERSION_DEPRECATED") {
          notification['status'] = "DEPRECATED";
        } else {
          notification['status'] = "UNKNOWN";
        }
        
        this.notificationsTableData.push(notification);
      }     

      // TODO: Add LENS_VERSION_DEPRECATED
      this.loadingNotificationsTableData = false;
     
    }
  },
  created() { },
  async mounted() { 
    await LensService.getLenses().then(
      response => { 
        this.lenses = response.lens_summaries;
      },
      error => {   
        console.log(error);
        this.errorToastMessage = "Error getting Lenses. Please try again later.";
        this.showErrorToast = true;
      }      
    );    
    await LensService.getPillars().then(
      response => { 
        this.pillars = response.pillars;
      },
      error => {   
        console.log(error);
        this.errorToastMessage = "Error getting Pillars. Please try again later.";
        this.showErrorToast = true;
      }      
    );    
    await WorkloadService.getWorkloadsNotifications().then(
      response => { 
        this.workloadsNotifications = response.notifications;
        this.loadingWorkloadNotifications = false;
      },
      error => {   
        console.log(error);
        this.errorToastMessage = "Error loading Workloads Notifications. Please try again later.";
        this.showErrorToast = true;
      }      
    )
    await this.processWorkloadsNotifications();
    this.loading = false;

  },
  watch: {
    $route: {
      immediate: true,
      handler (route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page)
        }
      }
    }
  },


};



</script>